import { useState } from "react";

import { BASE_URL } from "./utils/constants";
import PropertiesMap from "./PropertiesMap/PropertiesMap";
import "./App.css";
import Login from "./Login";

const BASE_PATH = window.location.origin;
// const isProd = !BASE_PATH.includes("dev.") && !BASE_PATH.includes("qa.") && !BASE_PATH.includes("uat.");
const isProd = !/dev.|qa.|uat./.test(BASE_PATH);

// to embed in 3rd party app
// <iframe src='http://localhost:3001' allow="geolocation" title="map" frameborder='0' width="100%" height="500" />

const App = () => {
  const [authorize, setAuthorize] = useState(isProd);

  const authorizeUser = async user => {
    const apiEndpoint = `${BASE_URL}/user/login`;
    const response = await fetch(apiEndpoint, {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then(response => response.json());
    if (!response.success) {
      console.log("Error: ", response.message);
      return false;
    }
    setAuthorize(true);
    return true;
  };

  return (
    <>
      {authorize ? (
        <div className="App">
          <PropertiesMap />
        </div>
      ) : !isProd ? (
        <Login onSubmit={authorizeUser} />
      ) : null}
    </>
  );
};

export default App;
