import { GoogleMap, InfoBox, Marker, MarkerClusterer, useJsApiLoader } from "@react-google-maps/api";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _map from "lodash/map";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import CustomIcon from "../CustomIcon/CustomIcon";
// import ClusterMarkerIcon from "../images/cluster-marker.svg";
// import MarkerIcon from "../images/marker.svg";
// import PublishedMarkerIcon from "../images/published-marker.svg";
import ClusterMarkerIcon from "../images/cluster-marker-1.svg";
import MarkerIcon from "../images/marker-1.svg";
import PublishedMarkerIcon from "../images/published-marker-1.svg";
import { BASE_URL, MAP_API_KEY, propertySearchLink } from "../utils/constants";
import { checkIfEmpty, convertToInternatialFormat, convertToSlug } from "../utils/utils";
import "./PropertiesMap.css";

const mapOptions = () => ({
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: false,
  streetViewControl: true,
  rotateControl: false,
  fullscreenControl: true,
  mapTypeId: "hybrid",
  minZoom: 1,
});

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const PropertiesMap = ({ defaultCenter }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAP_API_KEY,
  });

  const [allowedCurrentLocation, setAllowedCurrentLocation] = useState(false);
  const [_center, setCenter] = useState(defaultCenter);
  const [_location, setLocation] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [_zoom, setZoom] = useState(2.4);

  useEffect(() => {
    fetchList();
    navigator.geolocation.getCurrentPosition(
      position => {
        setAllowedCurrentLocation(true);
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setZoom(8);
      },
      err => {
        console.log(`permission denied:${err}`);
      },
    );
  }, []);

  const _onClickMarker = id => {
    setActiveMarker(activeMarker === id ? null : id);
  };

  const fetchList = () => {
    const apiEndpoint = `${BASE_URL}/portfolio/search`;
    fetch(apiEndpoint, {
      method: "GET",
    })
      .then(response => response.json())
      .then(json => setLocation(json?.content));
  };

  const _renderMarkers = clusterer => {
    if (!_isEmpty(_location) && _isArray(_location)) {
      return _map(
        _location,
        (
          {
            property_code,
            building_latitude,
            building_longitude,
            building_name,
            building_address1,
            building_address2,
            building_city,
            building_state_code,
            building_postal_code,
            building_area_total_area,
            building_status,
            asset_title_name,
          },
          key,
        ) => {
          const isPublished = building_status === "published";
          const lat = parseFloat(building_latitude);
          const lng = parseFloat(building_longitude);
          const _zipCode = checkIfEmpty(building_postal_code);
          const _city = checkIfEmpty(building_city);
          const icon = isPublished ? PublishedMarkerIcon : MarkerIcon;
          const address1 = checkIfEmpty(building_address1);
          const address2 = checkIfEmpty(building_address2);
          const area = _isNumber(building_area_total_area) ? Number(building_area_total_area.toFixed()) : "";
          return (
            <Marker
              key={key}
              icon={icon}
              position={{ lat, lng }}
              clusterer={clusterer}
              onClick={() => _onClickMarker(property_code)}
            >
              {activeMarker === property_code && (
                <InfoBox
                  options={{
                    closeBoxURL: "",
                    enableEventPropagation: true,
                    boxClass: "info-box-container",
                  }}
                  position={{ lat, lng }}
                >
                  <div>
                    <CustomIcon name="close" className="close-icon" onClick={() => _onClickMarker(property_code)} />
                    <p className="building-name">{building_name}</p>
                    {address1 && <p>{address1}</p>}
                    {address2 && <p>{address2}</p>}
                    <p className="mg-bottom-5">
                      {`${_city} `}
                      {building_state_code && building_state_code !== "N/A"
                        ? `${building_state_code} ${_zipCode}`
                        : _zipCode}
                    </p>
                    <p>{`${convertToInternatialFormat(area)} square feet`}</p>
                    {isPublished && (
                      <p className="mg-top-5">
                        This property is currently available. View more details on&nbsp;
                        <a
                          href={`${propertySearchLink}${property_code}-${convertToSlug(asset_title_name)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Prologis Property Search.
                        </a>
                      </p>
                    )}
                  </div>
                </InfoBox>
              )}
            </Marker>
          );
        },
      );
    }
    if (!_isEmpty(_location)) {
      const { latitude, longitude } = _location;
      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);
      return <Marker position={{ lat, lng }} />;
    }
    return null;
  };

  const onLoad = useCallback(_mapInstance => {
    _mapInstance.setOptions(mapOptions()); // hide extra buttons
  }, []);

  return (
    <div className="map-container">
      {isLoaded ? (
        <GoogleMap
          id="map"
          mapContainerStyle={containerStyle}
          center={_center}
          zoom={_zoom}
          extraMapTypes={[]}
          onLoad={onLoad}
        >
          <>
            <MarkerClusterer
              styles={[
                {
                  url: ClusterMarkerIcon,
                  height: 40,
                  width: 40,
                  textColor: "#fff",
                },
                // {
                //   url: ClusterMarkerIcon,
                //   height: 34,
                //   width: 34,
                //   textColor: "#fff",
                // },
                // {
                //   url: ClusterMarkerIcon,
                //   height: 34,
                //   width: 34,
                //   textColor: "#fff",
                // },
                // {
                //   url: ClusterMarkerIcon,
                //   height: 40,
                //   width: 40,
                //   textColor: "#fff",
                // },
                // {
                //   url: ClusterMarkerIcon,
                //   height: 46,
                //   width: 46,
                //   textColor: "#fff",
                // },
              ]}
              onFocus
              clusterClass="cluster-marker"
            >
              {clusterer => _renderMarkers(clusterer)}
            </MarkerClusterer>
            {allowedCurrentLocation && <Marker position={{ lat: _center?.lat, lng: _center?.lng }} />}
          </>
        </GoogleMap>
      ) : null}
    </div>
  );
};

PropertiesMap.defaultProps = {
  defaultCenter: {
    lat: 38.805470223177466,
    lng: -118.76220703125,
  },
};

PropertiesMap.propTypes = {
  defaultCenter: PropTypes.object,
};

export default PropertiesMap;
