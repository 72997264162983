import { useState } from "react";
import "./Login.css";

const LoginForm = ({ onSubmit }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setErrorMessage(false);
    setLoader(true);
    const user = { username: document.forms[0].username.value, password: document.forms[0].password.value };
    const response = await onSubmit(user);
    if (!response) {
      setErrorMessage(true);
    }
    setLoader(false);
  };

  return (
    <div className="login">
      <div className="login-form">
        <div className="title">Sign In</div>
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label>Username </label>
              <input type="text" name="username" required />
            </div>
            <div className="input-container">
              <label>Password </label>
              <input type="password" name="password" required />
            </div>
            {loader ? <p className="info">Validating ...</p> : null}
            {errorMessage ? <p className="error">Invalid credentials</p> : null}
            <div className="button-container">
              <input type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
