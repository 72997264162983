import PropTypes from "prop-types";
import React from "react";
import * as SvgCollection from "./svg";
import QuestionMark from "./svg/questionMark.svg";

const CustomIcon = ({ name, size, ...props }) => {
  const svg = SvgCollection[name];

  if (size) {
    props.width = size;
    props.height = size;
  }

  if (svg) return <img src={svg} alt="" style={{ verticalAlign: "top" }} {...props} />;

  return <img src={QuestionMark} alt="" style={{ verticalAlign: "top" }} {...props} />;
};

CustomIcon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
};

export default CustomIcon;
