import _isEmpty from "lodash/isEmpty";

export const convertToSlug = text => {
  if (!text) {
    return "N/A";
  }
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

export const convertToInternatialFormat = x => x.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

export const checkIfEmpty = str => {
  if (_isEmpty(str) || str === "N/A") return "";
  return str;
};
